import { ReactNode } from "react";
import { ColumnDef, Table } from "@tanstack/react-table";
import { DataTable } from "@/components/ui/data-table";
import { CustomerInvoice } from "avail-types";
import { curr } from "@/lib/curr";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Checkbox } from "@/components/ui/checkbox";
import { QueryData, TableState, useManualTable } from "@/lib/table";
import Toolbar from "@/components/ui/toolbar";
import { UseQueryResult } from "@tanstack/react-query";
import { usePayRequest } from "@/lib/orders";
import { Badge } from "@/components/ui/badge";
import { ArrowRight } from "lucide-react";
import { formatDate } from "@/lib/dates";

const COLUMNS: ColumnDef<CustomerInvoice>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => {
      if (!row.original.balance) {
        return null;
      }
      return (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "increment_id",
    header: "#",
    cell: ({ getValue, row }) => (
      <Link to={`/invoices/${row.original.code}`} className="font-medium">
        {getValue<string>()}
      </Link>
    ),
  },
  {
    header: "Invoice Date",
    accessorKey: "invoiced_at",
    cell: ({ getValue }) => formatDate(getValue<string>()),
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "order_type.name",
    header: "Order Type",
  },
  {
    accessorKey: "status",
    header: "Status",
    enableSorting: false,
    cell: ({ getValue }) => <Badge>{getValue<string>()}</Badge>,
  },
  {
    accessorKey: "total",
    header: "Total",
    cell: ({ getValue }) => curr(getValue<number>()),
  },
  {
    accessorKey: "balance",
    header: "Balance",
    cell: ({ getValue }) => curr(getValue<number>()),
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => (
      <Button asChild variant="link" size="sm">
        <Link to={`/orders/${row.original.code}`}>
          <ArrowRight className="size-5 text-muted-foreground" />
        </Link>
      </Button>
    ),
  },
];

export default function InvoicesTable({
  header,
  tableState,
  query,
}: {
  header?: (table: Table<CustomerInvoice>) => ReactNode;
  tableState: TableState;
  query: UseQueryResult<QueryData<CustomerInvoice>>;
}) {
  const table = useManualTable(tableState, query, COLUMNS);
  const { rows: selected } = table.getSelectedRowModel();
  const payRequest = usePayRequest();

  return (
    <div>
      {selected.length > 0 && (
        <Toolbar
          actions={
            <Button
              isLoading={payRequest.isLoading}
              onClick={() =>
                payRequest.mutate({
                  invoices: selected.map((r) => r.original.code),
                })
              }
            >
              Pay
            </Button>
          }
          className="py-2"
        >
          {selected.length} {selected.length === 1 ? "invoice" : "invoices"}{" "}
          selected
        </Toolbar>
      )}
      <DataTable
        header={header && header(table)}
        table={table}
        isLoading={query.isLoading}
      />
    </div>
  );
}
