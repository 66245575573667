import { imageUrl } from "@/lib/image";
import ImageAnchor from "@/components/ui/image-anchor";
import { Proof } from "avail-types";
import { HTMLAttributes } from "react";

interface ProofImageProps extends HTMLAttributes<HTMLAnchorElement> {
  proof: Proof;
}

export default function ProofImage({ proof, ...other }: ProofImageProps) {
  return (
    <ImageAnchor href={proof.pdf || proof.image} {...other}>
      <img
        src={imageUrl(proof.image, { w: 600 })}
        alt={proof.file_name}
        className="w-full"
      />
    </ImageAnchor>
  );
}
