import { PencilIcon } from "lucide-react";
import { ProjectOption } from "avail-types";
import ProjectOptionDuplicateButton from "@/components/projects/ProjectOptionDuplicateButton";
import ProjectOptionDeleteButton from "@/components/projects/ProjectOptionDeleteButton";
import ProjectOptionSampleOrderDialog from "@/components/projects/ProjectOptionSampleOrderDialog";

export default function ProjectOptionGroupMenu({
  onEditOption,
  option,
}: {
  onEditOption: (o: ProjectOption) => void;
  option: ProjectOption;
}) {
  return (
    <div
      className="invisible absolute right-3 top-3 flex space-x-3 group-hover:visible"
      onClick={(e) => e.stopPropagation()}
    >
      <button type="button" onClick={() => onEditOption(option)}>
        <PencilIcon className="size-5 text-muted-foreground" />
      </button>
      <ProjectOptionDuplicateButton option={option} />
      <ProjectOptionDeleteButton option={option} />
      <ProjectOptionSampleOrderDialog option={option} />
    </div>
  );
}
